.locl-auth-layout {
  position: relative;
  min-height: 100vh;
  background-color: var(--gray-color-300);

  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    background: rgb(241, 241, 241);
  }

  &__logo {
    height: 32px;
    width: 110px;
    display: block;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;

    object-fit: cover;
    width: 100%;
    height: 100%;
    background-position-x: 100%;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  &__body {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 92px 0;

    margin-top: 70px;

    .form__form-group-label {
      white-space: nowrap;
    }

    @media screen and (max-width: 1024px) {
      margin-top: 70px;
      padding: 32px 0;

      .registration-tablet-wrapper {
        padding-top: 0 !important;
      }
    }
  }

  &__footer {
    position: relative;
    z-index: 3;
    background-color: #f2f4f7;
  }
}
